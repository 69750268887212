/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// custom components
import Layout from '../components/layout';
import Header from '../components/header';
import SEO from '../components/seo';
import Client from '../components/Client';
import Intro from '../components/Intro';
import Card from '../components/Card';
import Grid from '../components/Grid';

// images
import winners from '../images/web-marketing-winners.svg';
import forward from '../images/forward.svg';
import speed from '../images/speed.svg';
import dataimage from '../images/data.svg';

const DarkSection = styled.section`
  background: #f7f7f7;
  padding: 2rem;
`;
const ClientContainer = styled.div`
  max-width: ${props => props.theme.maxwidth};
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 0;
`;

const GatsbyPage = ({ data }) => (
  <Layout>
    <SEO
      title="GatsbyJs Development and Gatsby Developers , Wellington New Zealand"
      description="Want blazing fast websites? We build with an recommend Gatsby for super fast customer and developer experiences. Learn more here"
    />
    <Header
      title="Gatsby Development"
      sub="We make incredibly fast websites using GatsbyJs. Experience the speed and freedom of bringing your data from anywhere to the web "
      image={winners}
    />
    <Intro>
      <h2 style={{ textAlign: 'center', marginBottom: '0rem' }}>Why build websites with Gatsby</h2>
      <Grid noMargin>
        <Card
          image={speed}
          alt="Speed"
          title="It's Fast in every way that matters"
          description="Fast for your customers so they love your site. Fast for google so you rank better. Fast for your developers making them more efficient"
        />
        <Card
          image={dataimage}
          alt="Bring your own data"
          title="Bring your own data"
          description="Is your content in wordpress? A headless CMS? Shopify? A spreadsheet? It really does not matter. We can pouplate the content on your website from wherever you store it."
        />
        <Card
          image={forward}
          alt="Future proof"
          title="Future Proof"
          description="Gatsby websites are made for the modern web, with progressive web app functionality baked right in."
        />
      </Grid>
    </Intro>

    <DarkSection>
      <div>
        <h2 style={{ textAlign: 'center' }}>Built with Gatsby</h2>
      </div>
      <ClientContainer>
        <Client
          image={data.base.childImageSharp.fluid}
          alt="Base Backpackers"
          title="Base Backpackers"
          sector="Tourism"
          tags={['Design', 'Development', 'GatsbyJs', 'DatoCMS', 'Stripe', 'Mews']}
          description="We transitioned Base Backpackers from Joomla to Gatsby so they could give their customers the fastest modern web experience. For content management we chose DatoCMS which is easy for all content managers to use."
        />
        <Client
          image={data.garden.childImageSharp.fluid}
          alt="Garden Burees"
          title="Garden Burees"
          sector="Tourism"
          tags={['Design', 'Development', 'GatsbyJs', 'Seo', 'DatoCMS']}
          description="We move the Garden Burees website from an old static website made in the early 2000's to Gatsby to give the owners freedom to edit their content whilst still getting the speed advantages of a static website."
        />
      </ClientContainer>
    </DarkSection>
  </Layout>
);

export default GatsbyPage;

GatsbyPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    base: file(relativePath: { eq: "base.jpg" }) {
      name
      childImageSharp {
        fluid(maxWidth: 700) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    garden: file(relativePath: { eq: "garden.jpg" }) {
      name
      childImageSharp {
        fluid(maxWidth: 700) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
