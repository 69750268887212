/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import styled from 'styled-components';
import media from './Media';

const CardWrapper = styled.div`
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 400px;

  padding: ${props => props.theme.globalPadding};
  @media ${media.tablet} {
    flex-basis: 33%;
  }
  h3 {
    line-height: 1.4;
    margin-bottom: 1rem;
  }
`;

const Card = ({
  image, alt, title, description,
}) => (
  <CardWrapper>
    <img src={image} alt={alt} width="100%" height="50%" style={{ maxHeight: '200px' }} />
    <h3>{title}</h3>
    <p>{description}</p>
  </CardWrapper>
);

export default Card;
