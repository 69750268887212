import styled from 'styled-components';

const Grid = styled.div`
  margin: ${props => (props.noMargin ? '0 auto' : '2rem auto 0')};
  display: flex;
  flex-wrap: wrap;
  padding-right: ${props => props.theme.globalPadding};
  max-width: ${props => props.theme.maxwidth};
  background: ${props => (props.dark ? '#f7f7f7' : '#fff')};
  align-items: center;
`;

export default Grid;
